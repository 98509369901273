const messages = {
  balancePriorToCurrentBill: 'Balance Prior to Current Bill',
  boldLabel: '15 minutes to an hour.',
  label: 'Please pay at least the minimum amount due and we will reconnect your service within ',
  minAmountDueText: 'Minimum Amount Due',
  minAmountHelperText: 'By paying only the minimum amount, your Other Charges remain past due.',
  pastDueSubjectLabel: 'Past Due Amount & Reconnect Charges',
  pastDueDisclaimerLabel: 'Includes Other Charges for products and services you are enrolled in.',
  reconnectionFeeText: 'Reconnection Fee',
  securityDepositText: 'Security Deposit*',
  selectPaymentTitle: 'Select your payment option.',
};

export default messages;
