import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';

import { useEligibility } from 'api/eligibility-api/eligibility-api';
import useConfig from 'hooks/useConfig';
import { googleTagManager } from 'services';

import { useUserState } from '../../auth/authenticate';
import { lockStatusErrorPath } from '../../urls-and-paths';

import { jLockViewCurrentBillClickEvent, jLockViewEvent } from './gtmEventsJLock';
import JLockPresentation from './JLock.presentation';

const JLock = (): React.ReactElement => {
  const {
    urls: { currentBillUrl = '' },
  } = useConfig();
  const navigate = useNavigate();
  const { isAuthenticated } = useUserState();

  const { eligibilityResponse } = useEligibility({
    queryKey: ['lock-status-eligibility'],
    enabled: isAuthenticated,
    onSuccess: (httpResponse: AxiosResponse<CombinedEligibilityCheckResponse>) => {
      const eligibilityResponseData = httpResponse.data;
      if (eligibilityResponseData.eligible.dunningLock !== 'Y') {
        window.location.href = currentBillUrl;
      }
    },
    onError: () => {
      navigate(lockStatusErrorPath);
    },
  });

  const onPageViewHandler = (): void => {
    googleTagManager.trackViewEvent({
      ...jLockViewEvent,
      authenticationStatus: isAuthenticated,
    });
  };

  const viewCurrentBillOnClickHandler = (): void => {
    googleTagManager.trackButtonClick({
      ...jLockViewCurrentBillClickEvent,
      authenticationStatus: isAuthenticated,
    });
    window.location.href = currentBillUrl;
  };

  return (
    <JLockPresentation
      eligibilityResponse={eligibilityResponse?.data}
      onPageViewHandler={onPageViewHandler}
      viewCurrentBillOnClickHandler={viewCurrentBillOnClickHandler}
    />
  );
};

export default JLock;
