import { AxiosResponse } from 'axios';

import useConfig from 'hooks/useConfig';

import { axiosGet, axiosPost } from './axios-adapter';

interface AzureConfigProps {
  baseURL: string | undefined;
  headers: {
    Authorization: string;
  };
}

// // sets bearer token
// const azureConfig = (): AzureConfigProps => ({
//   baseURL: process.env.REACT_APP_BACKEND_HOST,
//   headers: {
//     Authorization: `Bearer ${localStorage.getItem('JWT')}`,
//   },
// });

export const azureGet = async <T>(url: string): Promise<AxiosResponse<T>> => {
  const {
    baseUrls: { REACT_APP_BACKEND_HOST },
  } = useConfig();

  const azureConfig = (): AzureConfigProps => ({
    baseURL: REACT_APP_BACKEND_HOST,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('JWT')}`,
    },
  });

  return axiosGet(url, azureConfig());
};

export const azurePost = async <T>(url: string, body: unknown): Promise<AxiosResponse<T>> => {
  const {
    baseUrls: { REACT_APP_BACKEND_HOST },
  } = useConfig();

  const azureConfig = (): AzureConfigProps => ({
    baseURL: REACT_APP_BACKEND_HOST,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('JWT')}`,
    },
  });

  return axiosPost(url, body, azureConfig());
};
