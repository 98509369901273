import { useEffect } from 'react';
import { Box, styled, Theme, Typography } from '@mui/material';

import { useUserState } from 'auth/authenticate';
import ViewPaymentOffersLink from 'component-library/links/ViewPaymentOffersLink';
import { googleTagManager } from 'services';

import ViewCurrentBillLink from '../../component-library/links/ViewCurrentBillLink';
import LoadingSpinner from '../../component-library/loading-spinner/LoadingSpinner';
import { eligibilityTypes, routes } from '../../constants';
import { useAccountDetails, useConfig, usePaymentAgreementDetails, useRefactoredEligibility } from '../../hooks';
import { paGenericErrorPath } from '../../urls-and-paths';

import AccountInfoSection from './account-info/AccountInfoSection';
import messages from './account-info/messages';
import CashOnlySelection from './cash-only/CashOnlySelection';
import DownPaymentSelection from './down-payment-selection/DownPaymentSelection';
import EnrollmentAssistanceNotice from './EnrollmentAssistanceNotice';
import { EnrollmentProvider } from './EnrollmentContext';
import PlanSelectionSection from './plan-selection';

const Container = styled(Box)(({ theme }: { theme: Theme }) => ({
  [theme.breakpoints.down(376)]: {
    maxWidth: '327px',
  },
}));

const SectionContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
  [theme.breakpoints.between('tablet', 'desktop')]: {
    gap: '32px',
    width: '688px',
  },
  [theme.breakpoints.down('tablet')]: {
    gap: '32px',
  },
}));

const SubSectionContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
}));

const Heading = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.primary.main,
  gridArea: 'heading',
  lineHeight: '38.13px',
  margin: '24px 0 40px 0',
  textAlign: 'center',
  [theme.breakpoints.down('tablet')]: {
    margin: '24px 0',
  },
}));

const HeaderAndLinkContainer = styled('div')(() => ({
  gap: '24px',
}));

const PaymentAgreementEnrollmentContent: React.FC = (): React.ReactElement => {
  const { accountDetails } = useAccountDetails({ errorPagePath: paGenericErrorPath });
  const {
    urls: { currentBillUrl = '' },
  } = useConfig();
  const { paymentAgreementDetails } = usePaymentAgreementDetails({ shouldFetch: false });
  const { cashOnly } = paymentAgreementDetails;
  const { eligibilityType, isFetching, isError } = useRefactoredEligibility({
    errorRedirectPath: routes.error.paymentAgreement,
    requiredEligibilityTypes: [eligibilityTypes.PaymentAgreement, eligibilityTypes.PaymentOffers],
  });
  const { isAuthenticated } = useUserState();

  const isPaymentOffers = eligibilityType === eligibilityTypes.PaymentOffers;
  const isCashOnly = cashOnly === 'Y';

  const onViewCurrentBillClick = (): void => {
    const gtmOnViewCurrentBillClick = {
      actionDetail: isPaymentOffers ? 'return to offers page' : 'return to current bill',
      actionObject: isPaymentOffers ? 'view payment offers' : 'view current bill',
      transactionStepName: 'pa detail page',
      transactionType: 'payment agreement',
      userType: isCashOnly ? 'cash only' : 'web',
    };

    googleTagManager.trackButtonClick({ ...gtmOnViewCurrentBillClick, authenticationStatus: isAuthenticated });
    window.location.href = currentBillUrl;
  };

  useEffect(() => {
    if (!isFetching && eligibilityType) {
      const gtmPaEnrollmentPageLoad = {
        actionDetail: 'payment options overview',
        actionObject: 'pa details',
        transactionStepName: 'pa detail page',
        transactionType: 'payment agreement',
        userType: isCashOnly ? 'cash only' : 'web',
      };

      googleTagManager.trackViewEvent({ ...gtmPaEnrollmentPageLoad, authenticationStatus: isAuthenticated });
    }
  }, [eligibilityType, isFetching]);

  if (isFetching) {
    return <LoadingSpinner />;
  }

  if (
    !isError &&
    (eligibilityType === eligibilityTypes.PaymentAgreement || isPaymentOffers) &&
    paymentAgreementDetails.paymentOptions?.length &&
    accountDetails.accounts.length
  ) {
    return (
      <EnrollmentProvider accountDetails={accountDetails} paymentAgreementDetails={paymentAgreementDetails}>
        <Container>
          <HeaderAndLinkContainer>
            {isPaymentOffers ? (
              <ViewPaymentOffersLink isAuthenticated={isAuthenticated} isCashOnly={isCashOnly} />
            ) : (
              <ViewCurrentBillLink onClickHandler={onViewCurrentBillClick} />
            )}
            <Heading data-testid="account-info-heading" id="account-info-heading" fontSize={28}>
              {messages.heading}
            </Heading>
          </HeaderAndLinkContainer>
          <SectionContainer>
            <SubSectionContainer>
              <AccountInfoSection accountInfo={accountDetails} paymentDetails={paymentAgreementDetails} />
              <PlanSelectionSection paymentOptions={paymentAgreementDetails.paymentOptions} />
            </SubSectionContainer>
            {cashOnly === 'Y' ? <CashOnlySelection /> : <DownPaymentSelection />}
            <EnrollmentAssistanceNotice />
          </SectionContainer>
        </Container>
      </EnrollmentProvider>
    );
  }
  return <></>;
};

export default PaymentAgreementEnrollmentContent;
