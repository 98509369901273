import { styled, Theme, Typography } from '@mui/material';

import { ReadOnlyCurrencyMask } from 'component-library';

import { StyledListItem } from './StyledListItems';

type BreakdownListItemProps = IBreakdownListItem;

const BreakdownListItem: React.FC<BreakdownListItemProps> = (props: BreakdownListItemProps): React.ReactElement => {
  const { amount, itemDescription } = props;

  const StyledBreakdownListItemBox = styled(StyledListItem)(({ theme }: { theme: Theme }) => ({
    ...theme.typography.body1,
    borderBottom: `1px solid ${theme.palette.dteGrey.main}`,
    color: theme.palette.secondary.main,
  }));

  return (
    <StyledBreakdownListItemBox>
      <Typography>{itemDescription}</Typography>
      <Typography>{ReadOnlyCurrencyMask(amount)}</Typography>
    </StyledBreakdownListItemBox>
  );
};

export default BreakdownListItem;
