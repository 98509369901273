import * as React from 'react';
import { KeyboardEvent, useContext } from 'react';
import { ArrowForwardIosRounded } from '@mui/icons-material';
import { styled, Theme, Typography } from '@mui/material';

import {
  AdditionalAssistanceBanner,
  DefaultAssistanceMessage,
} from 'component-library/banners/AdditionalAssistanceBanner';
import { SuccessBanner } from 'component-library/banners/SuccessBanner';
import { Breadcrumb } from 'component-library/links/Breadcrumb';
import { StyledCtaLink } from 'component-library/links/StyledLink';
import { TabPanelContainer } from 'component-library/tabs/TabPanelContainer';
import { TabContext } from 'component-library/tabs/TabsContext';
import { Bold } from 'component-library/typography/Bold';
import { useConfig } from 'hooks';

import { LockStatusContent } from '../../LockStatusContent';

import { MilitaryServiceProtectionEligibility } from './MilitaryServiceProtectionEligibility';

type MilitaryPanelProps = {
  lockEndDate: string;
  viewCurrentBillOnClickHandler: () => void;
  energyAssistanceProgramsLinkOnClickHandler: () => void;
  unitedWayLinkOnClickHandler: () => void;
  viewMedicalLinkOnClickHandler: () => void;
};

export const MilitaryPanel = ({
  lockEndDate,
  viewCurrentBillOnClickHandler,
  energyAssistanceProgramsLinkOnClickHandler,
  unitedWayLinkOnClickHandler,
  viewMedicalLinkOnClickHandler,
}: MilitaryPanelProps): React.ReactElement => {
  const {
    urls: { currentBillUrl = '' },
  } = useConfig();
  const { panelTabChangeHandler } = useContext(TabContext);

  const TopContent = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  });

  const BannerAndTitle = styled('div')(({ theme }: { theme: Theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    [theme.breakpoints.down('tablet')]: {
      paddingBottom: '20px',
    },
    [theme.breakpoints.up('tablet')]: {
      paddingBottom: '24px',
    },
  }));

  const Title = styled(Typography)(({ theme }: { theme: Theme }) => ({
    color: theme.palette.primary.main,
    textAlign: 'center',
    fontSize: '28px',
    fontWeight: '400',
    lineHeight: '38px',
  }));

  const BottomContent = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
  });

  const handleTabChange = (): void => {
    panelTabChangeHandler(0);
    viewMedicalLinkOnClickHandler();
  };

  const viewMedicalLinkClickHandler = (): void => {
    handleTabChange();
  };

  const viewMedicalLinkKeyboardHandler = (event: KeyboardEvent): void => {
    if (event.code === 'Enter') {
      handleTabChange();
    }
  };

  return (
    <TabPanelContainer data-testid="military-panel">
      <LockStatusContent sx={{ gap: '0px' }}>
        <TopContent>
          <Breadcrumb text="View Current Bill" href={currentBillUrl} onClickHandler={viewCurrentBillOnClickHandler} />
          <BannerAndTitle>
            <SuccessBanner
              message={`Your DTE account is temporarily protected from a service interruption through ${lockEndDate}.`}
            />
            <Title>Eligibility for Military Service Protection</Title>
          </BannerAndTitle>
        </TopContent>
        <MilitaryServiceProtectionEligibility />
        <BottomContent>
          <AdditionalAssistanceBanner
            message={
              <DefaultAssistanceMessage
                energyAssistanceProgramsLinkOnClickHandler={energyAssistanceProgramsLinkOnClickHandler}
                unitedWayLinkOnClickHandler={unitedWayLinkOnClickHandler}
              />
            }
          />
          <StyledCtaLink
            sx={{ cursor: 'pointer', marginBottom: '-1px' }}
            onClick={viewMedicalLinkClickHandler}
            tabIndex={0}
            onKeyDown={viewMedicalLinkKeyboardHandler}
            data-testid="view-medical-link"
          >
            <Bold>View Medical</Bold>
            <ArrowForwardIosRounded sx={{ fontSize: '18px' }} />
          </StyledCtaLink>
        </BottomContent>
      </LockStatusContent>
    </TabPanelContainer>
  );
};
