import { Box, styled, SxProps, Theme, Typography, useTheme } from '@mui/material';

import DteDisclaimerIcon from 'component-library/icons/DteDisclaimerIcon';

import messages from './messages';

const NoticeTextContainer = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.primary.main,
  variant: 'body1',
}));

export const DisclaimerNotice: React.FC = (): React.ReactElement => {
  const theme = useTheme();

  const sx: Record<string, SxProps> = {
    noticeWrapperFirstText: {
      alignItems: 'flex-start',
      color: theme.palette.secondary.main,
      display: 'flex',
      gap: '20px',
    },
  };

  return (
    <Box data-testid="disclaimer-notice" sx={sx.noticeWrapperFirstText}>
      <DteDisclaimerIcon />
      <NoticeTextContainer data-testid="disclaimer-text">{messages.firstStepMessage}</NoticeTextContainer>
    </Box>
  );
};
