import useConfig from 'hooks/useConfig';

import { MonthlyPayment, MonthlyPaymentV2 } from './monthly-payment';
import { PaymentDetailsV2 as PaymentDetails } from './payment-details';

interface IEnrollmentDetailsProps {
  downPaymentAmount: string;
  downPaymentStatus: string;
  installmentAmount: string;
  numberOfInstallmentsOpted: number;
  numberOfInstallmentsRemaining: number;
}

interface IRenderMonthlyPaymentCircleProps {
  numberOfInstallmentsOpted: number;
  numberOfInstallmentsRemaining: number;
}

const RenderMonthlyPaymentCircle = ({
  numberOfInstallmentsOpted,
  numberOfInstallmentsRemaining,
}: IRenderMonthlyPaymentCircleProps): React.ReactElement | null =>
  numberOfInstallmentsOpted >= 25 ? null : (
    <>
      <MonthlyPaymentV2
        numberOfInstallmentsOpted={numberOfInstallmentsOpted}
        numberOfInstallmentsRemaining={numberOfInstallmentsRemaining}
      />
    </>
  );

const EnrollmentDetails: React.FC<IEnrollmentDetailsProps> = (props: IEnrollmentDetailsProps): React.ReactElement => {
  const {
    downPaymentAmount,
    downPaymentStatus,
    installmentAmount,
    numberOfInstallmentsOpted,
    numberOfInstallmentsRemaining,
  } = props;

  const {
    flags: { enablePAMonthlyRedesign },
  } = useConfig();

  return (
    <>
      <PaymentDetails
        downPaymentAmount={downPaymentAmount}
        downPaymentStatus={downPaymentStatus}
        installmentAmount={installmentAmount}
        numberOfInstallmentsOpted={numberOfInstallmentsOpted}
      />
      {enablePAMonthlyRedesign ? (
        <RenderMonthlyPaymentCircle
          numberOfInstallmentsOpted={numberOfInstallmentsOpted}
          numberOfInstallmentsRemaining={numberOfInstallmentsRemaining}
        />
      ) : (
        <>
          <MonthlyPayment
            numberOfInstallmentsOpted={numberOfInstallmentsOpted}
            numberOfInstallmentsRemaining={numberOfInstallmentsRemaining}
          />
        </>
      )}
    </>
  );
};
export default EnrollmentDetails;
