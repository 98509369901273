import * as React from 'react';
import { Helmet } from 'react-helmet-async';

import { reconnectService } from 'component-library/header/header';
import HeaderDesktop from 'component-library/header/HeaderDesktop';
import HeaderMobile from 'component-library/header/HeaderMobile';
import { PageTemplate } from 'component-library/page-templates/PageTemplate';
import PageTitles from 'constants/pageTitles';
import { useResponsiveRendering } from 'theme/breakpoints/responsiveHooks';

import ProbateScreenContent from '../components/ProbateScreenContent';

const mobileHeader = <HeaderMobile titleText={reconnectService} isHeaderButtonRendered={false} />;
const desktopHeader = <HeaderDesktop titleText={reconnectService} isHeaderButtonRendered={false} />;

const ReconnectProbatePage: React.FC = (): React.ReactElement => {
  const pageHeader = useResponsiveRendering(mobileHeader, desktopHeader, desktopHeader);

  return (
    <>
      <Helmet>
        <title>{PageTitles.RECONNECT_PROBATE}</title>
      </Helmet>
      <PageTemplate
        header={pageHeader}
        allViewportPaddingTop="0px"
        tabletDesktopContentWidth="470px"
        dataTestId="probate-screen-container"
      >
        <ProbateScreenContent />
      </PageTemplate>
    </>
  );
};

export default ReconnectProbatePage;
