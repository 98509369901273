import React from 'react';
import { Helmet } from 'react-helmet-async';

import { PageTemplate } from 'component-library/page-templates/PageTemplate';
import { PageTitles } from 'constants/';
import { useResponsiveRendering } from 'theme/breakpoints/responsiveHooks';

import ReconnectServiceContent from './reconnect-service-content/ReconnectServiceContent';
import { desktopHeader, mobileHeader } from './ReconnectServicePageHeaders';

const ReconnectServicePage: React.FC = (): React.ReactElement => {
  const pageHeader = useResponsiveRendering(mobileHeader, desktopHeader, desktopHeader);

  return (
    <>
      <Helmet>
        <title>{PageTitles.RECONNECT_SERVICE}</title>
      </Helmet>
      <PageTemplate
        allViewportPaddingTop="16px"
        dataTestId="reconnect-service-container"
        header={pageHeader}
        tabletDesktopContentWidth="740px"
      >
        <ReconnectServiceContent />
      </PageTemplate>
    </>
  );
};

export default ReconnectServicePage;
