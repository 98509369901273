import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { eligibilityTypes } from 'constants/';

import refactoredEligibilityApi from '../api/refactored-eligibility-api/refactor-eligibility-api';
import { useUserState } from '../auth/authenticate';
import { getEligibilityRoute } from '../services';

import useConfig from './useConfig';

interface IUseRefactoredEligibilityParams {
  errorRedirectPath: string;
  shouldPreventRedirect?: boolean;
  requiredEligibilityTypes: EligibilityTypes[];
}

interface IUseRefactoredEligibilityResult {
  eligibilityType: EligibilityTypes | null;
  isError?: boolean;
  isFetched?: boolean;
  isFetching?: boolean;
  isSuccess: boolean;
}

const useRefactoredEligibility = (params: IUseRefactoredEligibilityParams): IUseRefactoredEligibilityResult => {
  const { errorRedirectPath, requiredEligibilityTypes, shouldPreventRedirect } = params;

  const {
    urls: { currentBillUrl = '' },
  } = useConfig();
  const { isAuthenticated } = useUserState();
  const navigate = useNavigate();
  const { data, isError, isFetched, isFetching, isSuccess } = useQuery({
    enabled: isAuthenticated,
    queryKey: ['refactored-eligibility-check'],
    queryFn: refactoredEligibilityApi,
    retry: 0,
    refetchOnWindowFocus: false,
    onSuccess: (httpResponse: AxiosResponse<IRefactoredEligibilityResponse>) => {
      const { messageReply, eligible } = httpResponse.data;
      if (messageReply.replyCode.toLowerCase() !== 'success') {
        navigate(errorRedirectPath);
      }

      if (!shouldPreventRedirect && eligible && !requiredEligibilityTypes.includes(eligible.eligibilityType)) {
        // If condition necessary to replicate the old reconnect behavior to redirect to current bill url
        if (requiredEligibilityTypes.includes(eligibilityTypes.Reconnect)) {
          window.location.href = currentBillUrl;
        } else {
          const route = getEligibilityRoute(
            eligible.eligibilityType,
            eligible.paymentAgreement,
            eligible.paymentExtension,
          );

          navigate(route);
        }
      }
    },
    onError: () => navigate(errorRedirectPath),
  });

  return {
    eligibilityType: (data?.data.eligible.eligibilityType as EligibilityTypes) || null,
    isError,
    isFetched,
    isFetching,
    isSuccess,
  };
};

export default useRefactoredEligibility;
