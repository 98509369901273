import PageHeaders from 'constants/pageHeader';
import {
  base404ForPaAndPo,
  notFound404BackToCurrentBillClickEvent,
  Page404ForPaAndPoEventLabels,
} from 'gtm/gtmEvents404';

const getGtmViewEvent = (headerTitle: string): GtmEventProps => {
  switch (headerTitle) {
    case PageHeaders.PAYMENT_AGREEMENT:
      return {
        ...base404ForPaAndPo,
        actionObject: Page404ForPaAndPoEventLabels.backToCurrentBill,
        transactionStepName: Page404ForPaAndPoEventLabels.transactionStepNamePa,
      };

    case PageHeaders.PAYMENT_EXTENSION:
      return {
        actionDetail: '404 error page',
        actionObject: 'back to current bill',
        transactionStepName: 'collections error',
        transactionType: 'payment extension',
      };

    case PageHeaders.PAYMENT_OFFERS:
      return {
        ...base404ForPaAndPo,
        actionObject: Page404ForPaAndPoEventLabels.backToCurrentBill,
        transactionStepName: Page404ForPaAndPoEventLabels.transactionStepNamePo,
      };

    case PageHeaders.SERVICE_PROTECTION:
      return { ...notFound404BackToCurrentBillClickEvent, authenticationStatus: true };

    default:
      return {
        actionDetail: '404 error page',
        actionObject: 'back to current bill',
        transactionStepName: 'collections error',
        transactionType: 'reconnect',
      };
  }
};

export default getGtmViewEvent;
