import React from 'react';
import { Box, SxProps } from '@mui/material';

import { DtePrimaryButton } from 'component-library/buttons/DtePrimaryButton';
import { dayjsDate } from 'component-library/dates/dayjsApStyleAbbreviateMonths';
import { DteCtaLink } from 'component-library/links/DteCtaLink';
import useConfig from 'hooks/useConfig';

import { PaymentExtensionDate } from '../components/PaymentExtensionDate';
import { PaymentExtensionScheduledHeading } from '../components/PaymentExtensionScheduledHeading';
import { PaymentExtensionScheduledNotice } from '../components/PaymentExtensionScheduledNotice';

import messages from './messages';
import onCurrentBillButtonClick from './onCurrentBillButtonClick';
import onViewScheduledPaymentClick from './onViewScheduledPaymentButtonClick';

interface IPaymentExtensionScheduledContentProps {
  paymentExtensionDetails: IPaymentExtensionDetails;
}

export const PaymentExtensionScheduledContent = (props: IPaymentExtensionScheduledContentProps): React.ReactElement => {
  const { paymentExtensionDetails } = props;

  const {
    urls: { accountHistoryUrlFromSchedueledPe = '' },
  } = useConfig();
  const sx: Record<string, SxProps> = {
    rootWrapper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: '40px',
      marginTop: '40px',
    },
    peDateButtonAndCta: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: '32px',
      width: '100%',
    },
  };

  const formattedLockExpiryDate = dayjsDate(paymentExtensionDetails!.lockExpiryDate!);

  return (
    <Box data-testid="payment-extension-scheduled-content" sx={sx.rootWrapper}>
      <PaymentExtensionScheduledHeading />
      <Box sx={sx.peDateButtonAndCta}>
        <PaymentExtensionDate date={formattedLockExpiryDate} fontVariant="h2" />
        <DtePrimaryButton name={messages.viewCurrentBillButton} onClick={onCurrentBillButtonClick} />
        <DteCtaLink
          href={accountHistoryUrlFromSchedueledPe}
          name={messages.viewScheduledPaymentButton}
          onClick={onViewScheduledPaymentClick}
        />
      </Box>
      <PaymentExtensionScheduledNotice date={formattedLockExpiryDate} />
    </Box>
  );
};

export default PaymentExtensionScheduledContent;
