import { useUserState } from 'auth/authenticate';
import useConfig from 'hooks/useConfig';
import googleTagManager from 'services/googleTagManager';

import HeaderDesktop from '../../component-library/header/HeaderDesktop';
import HeaderMobile from '../../component-library/header/HeaderMobile';
import { PageHeaders } from '../../constants';
import { useResponsiveRendering } from '../../theme/breakpoints/responsiveHooks';

interface PaymentAgreementHeaderTypes {
  displayCancelButton: boolean;
  transactionStepName?: string;
  userType?: string;
}

const PaymentAgreementHeader = (props: PaymentAgreementHeaderTypes): JSX.Element => {
  const { displayCancelButton, transactionStepName, userType } = props;
  const {
    flags: { enableCenteredHeader },
    urls: { currentBillUrl = '' },
  } = useConfig();
  const { isAuthenticated } = useUserState();

  const gtmCancelButtonClick = {
    actionDetail: 'cancel',
    actionObject: 'cancel',
    transactionStepName,
    transactionType: 'payment agreement',
    userType,
  };

  const headerCancelButtonHandler = (): void => {
    if (transactionStepName && userType) {
      googleTagManager.trackButtonClick({
        ...gtmCancelButtonClick,
        authenticationStatus: isAuthenticated,
      });
    }
    window.location.href = currentBillUrl;
  };

  const mobileHeader = (
    <HeaderMobile
      isHeaderButtonRendered={displayCancelButton}
      onClick={headerCancelButtonHandler}
      titleText={PageHeaders.PAYMENT_AGREEMENT}
    />
  );
  const desktopHeader = (
    <HeaderDesktop
      isHeaderButtonRendered={displayCancelButton}
      onClick={headerCancelButtonHandler}
      titleText={PageHeaders.PAYMENT_AGREEMENT}
      enableCenteredHeader={enableCenteredHeader}
    />
  );
  const pageHeader = useResponsiveRendering(mobileHeader, desktopHeader, desktopHeader);

  return pageHeader;
};

export default PaymentAgreementHeader;
