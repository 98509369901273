import { styled } from '@mui/material';

import useConfig from 'hooks/useConfig';

import { DteSecondaryButton } from '../../buttons/DteSecondaryButton';

import messages from './messages';

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  height: '40px',
  width: '300px',
}));

const ViewCurrentBillButton: React.FC<ErrorPageViewCurrentBillClickHandler> = (
  props: ErrorPageViewCurrentBillClickHandler,
): React.ReactElement => {
  const { onClick } = props;
  const {
    urls: { currentBillUrl = '' },
  } = useConfig();

  const handleClick = (): void => {
    if (onClick) {
      onClick();
    }
    window.location.href = currentBillUrl;
  };

  return (
    <Container>
      <DteSecondaryButton name={messages.viewCurrentBillButtonLabel} onClick={handleClick} />
    </Container>
  );
};

export default ViewCurrentBillButton;
