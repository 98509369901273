import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, SxProps, Typography } from '@mui/material';

import DteErrorIcon from 'component-library/icons/DteErrorIcon';
import { theme } from 'theme/theme';

import messages from '../../paymentOptions/messages';

const MpscSelectPayments: React.FC = (): React.ReactElement => {
  const sx: Record<string, SxProps> = {
    optionDescription: {
      color: theme.palette.primary.main,
      paddingBottom: '12px',
    },

    failOptionDescription: {
      color: theme.palette.dteRed.dark,
      paddingBottom: '12px',
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
    },
  };

  const { formState } = useFormContext();

  const validationsSx =
    formState.errors.PaymentOptions?.type !== 'noOptionSelected' ? sx.optionDescription : sx.failOptionDescription;

  return (
    <Box sx={validationsSx}>
      {formState.errors.PaymentOptions?.type === 'noOptionSelected' ? <DteErrorIcon /> : ''}
      <Typography data-testid="select-payment-title" fontWeight={600} variant="body1">
        {messages.selectPaymentTitle}
      </Typography>
    </Box>
  );
};

export default MpscSelectPayments;
