import { useEffect } from 'react';
import { Box, Button, Link, SxProps, Typography, useTheme } from '@mui/material';

import { useUserState } from 'auth/authenticate';
import { probateBackToCurrentBillClickEvent, probateCallUsClickEvent, probateViewEvent } from 'gtm/gtmEventsReconnect';
import { useGoogleTagManager } from 'gtm/useGoogleTagManager';
import useConfig from 'hooks/useConfig';
import { googleTagManager } from 'services';

const customerContactNumber = '(855) 838-7258';

const ProbateScreenContent: React.FC = (): React.ReactElement => {
  const {
    urls: { currentBillUrl = '' },
  } = useConfig();
  const { isAuthenticated } = useUserState();
  const isGoogleTagManagerLoaded = useGoogleTagManager();
  const theme = useTheme();

  useEffect(() => {
    if (isGoogleTagManagerLoaded && isAuthenticated) {
      googleTagManager.trackViewEvent({
        ...probateViewEvent,
        authenticationStatus: isAuthenticated,
      });
    }
  }, [isGoogleTagManagerLoaded, isAuthenticated]);

  const sx: Record<string, SxProps> = {
    contentWrapper: {
      margin: '40px 0px',
    },
    center: {
      textAlign: 'center',
    },
    title: {
      color: theme.palette.primary.main,
    },
    subtitle: {
      margin: '32px 0px',
      color: theme.palette.secondary.main,
    },
    callUsButton: {
      margin: 'auto',
      background: theme.palette.dteBlue.main,
      textTransform: 'none',
      color: theme.palette.common.white,
      height: '40px',
      borderRadius: 'unset',
      width: '100%',
      maxWidth: '300px',
      boxShadow: 'unset',
      fontWeight: '600px',
      marginBottom: '20px',
      '&:hover': {
        background: theme.palette.dteBlue.dark,
        boxShadow: 'unset',
      },
      '&:focus': {
        background: theme.palette.dteBlue.dark,
        boxShadow: 'unset',
      },
    },
    backToCurrentBillButton: {
      margin: 'auto',
      background: theme.palette.common.white,
      textTransform: 'none',
      color: theme.palette.dteBlue.main,
      height: '40px',
      borderRadius: 'unset',
      border: `1px solid ${theme.palette.dteBlue.main}`,
      width: '100%',
      maxWidth: '300px',
      boxShadow: 'unset',
      fontWeight: '600px',
      '&:hover': {
        background: theme.palette.dteBlue.dark,
        color: theme.palette.common.white,
        boxShadow: 'unset',
      },
      '&:focus': {
        background: theme.palette.dteBlue.dark,
        color: theme.palette.common.white,
        boxShadow: 'unset',
      },
    },
    contactNumber: {
      color: theme.palette.dteBlue.main,
      cursor: 'pointer',
      textDecoration: 'underline',
      '&:hover': {
        color: theme.palette.dteBlue.dark,
      },
    },
    wordWrap: {
      overflowWrap: 'break-word',
      whiteSpace: 'nowrap',
    },
  };

  const redirectToCurrentBillHandler = (): void => {
    googleTagManager.trackButtonClick({
      ...probateBackToCurrentBillClickEvent,
      authenticationStatus: isAuthenticated,
    });
    window.location.href = currentBillUrl;
  };

  return (
    <Box sx={sx.contentWrapper}>
      <Box sx={sx.center}>
        <Typography variant="title" data-testid="probate-screen-title" sx={sx.title}>
          Contact Us
        </Typography>
      </Box>
      <Box sx={sx.subtitle}>
        <Typography variant="body1" data-testid="probate-screen-body">
          {`We need additional information to resolve an issue before we reconnect your service. Please call our Probate
          Department at `}
          <Box component="span" sx={sx.wordWrap}>
            <Link
              variant="body2"
              sx={sx.contactNumber}
              href={`tel:${customerContactNumber}`}
              data-testid="probate-screen-contact-number"
              fontWeight={600}
            >
              {customerContactNumber}
            </Link>
          </Box>
          {` and select Option #2.`}
        </Typography>
      </Box>
      <Box sx={sx.center}>
        <Button
          component={Link}
          href={`tel:${customerContactNumber}`}
          variant="contained"
          sx={sx.callUsButton}
          data-testid="probate-screen-call-us-button"
          onClick={(): void =>
            googleTagManager.trackButtonClick({
              ...probateCallUsClickEvent,
              authenticationStatus: isAuthenticated,
              number: customerContactNumber,
            })
          }
        >
          <Typography fontWeight={600} variant="body2" data-testid="probate-screen-call-us-text">
            Call Us
          </Typography>
        </Button>
      </Box>
      <Box sx={sx.center}>
        <Button
          variant="contained"
          sx={sx.backToCurrentBillButton}
          data-testid="back-to-current-bill-button"
          onClick={redirectToCurrentBillHandler}
        >
          <Typography fontWeight={600} variant="body2" data-testid="back-to-current-bill-text">
            Back to Current Bill
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default ProbateScreenContent;
