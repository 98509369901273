import useConfig from 'hooks/useConfig';
import { googleTagManager } from 'services';

import gtmPeEligibleBaseEvent from './gtmPeEligibleBaseEvent';

const onCurrentBillButtonClick = (): void => {
  const {
    urls: { currentBillUrl = '' },
  } = useConfig();
  googleTagManager.trackButtonClick({
    ...gtmPeEligibleBaseEvent,
    actionDetail: 'pe eligible details',
    actionObject: 'view current bill',
    authenticationStatus: true,
  });
  window.location.href = currentBillUrl;
};

export default onCurrentBillButtonClick;
