import { Box, Link, SxProps, Typography, useTheme } from '@mui/material';

import CashPaymentIcon from 'component-library/icons/CashPayment';
import { useConfig } from 'hooks';

import messages from './messages';

const CashPaymentNotice: React.FC = (): React.ReactElement => {
  const theme = useTheme();
  const {
    urls: { kioskUrl = '', paymentLocationUrl = '' },
  } = useConfig();

  const sx: Record<string, SxProps> = {
    noticeWrapperTitle: {
      border: `1px solid ${theme.palette.dteGrey.main}`,
      color: theme.palette.secondary.main,
      display: 'flex',
      padding: '30px 20px 30px 20px',
      gap: '20px',
      [theme.breakpoints.down(376)]: {
        flexDirection: 'column',
      },
      [theme.breakpoints.up(376)]: {
        flexDirection: 'row',
      },
      [theme.breakpoints.down('mobile')]: {
        maxWidth: '327px',
        height: '266px',
      },
      [theme.breakpoints.up('mobile')]: {
        width: '100%',
      },
      [theme.breakpoints.up(755)]: {
        height: '140px',
      },
      [theme.breakpoints.up('desktop')]: {
        width: '740px',
        height: '140px',
      },
    },
    titleWrapper: {
      alignSelf: 'center',
      color: theme.palette.secondary.main,
      fontSize: '16px',
      fontWeight: '600',
    },
    messageChunks: {
      color: theme.palette.secondary.main,
      display: 'inline',
      fontSize: '16px',
    },
    externalLinks: {
      color: theme.palette.dteBlue.main,
      fontWeight: '600',
      textDecorationColor: theme.palette.dteBlue.main,
      '&:hover': {
        color: theme.palette.dteBlue.dark,
        textDecorationColor: theme.palette.dteBlue.dark,
      },
    },
  };

  return (
    <Box data-testid="cash-payment-agreement-notice" sx={sx.noticeWrapperTitle}>
      <CashPaymentIcon />
      <Box sx={sx.titleWrapper}>
        <Typography sx={sx.messageChunks}>
          <strong>{messages.messageChunk1}</strong> {messages.messageChunk2}{' '}
          <Link id="dteCtaLink" href={kioskUrl} rel="noreferrer" sx={sx.externalLinks} target="_blank" variant="body1">
            {messages.dteKiosk}
          </Link>{' '}
          {messages.messageChunk3}{' '}
          <Link
            id="dteCtaLink"
            href={paymentLocationUrl}
            rel="noreferrer"
            sx={sx.externalLinks}
            target="_blank"
            variant="body1"
          >
            {messages.authorizedPaymentLocations}
          </Link>{' '}
          {messages.messageChunk4}
        </Typography>
      </Box>
    </Box>
  );
};

export default CashPaymentNotice;
