import { Box, SxProps } from '@mui/material';

import { DisclaimerNotice } from './DisclaimerNotice';
import OtherChargesNotice from './OtherChargesNotice';

interface Props {
  hasOtherCharges?: Boolean;
}

const ReconnectNoticeContent: React.FC<Props> = (props: Props): React.ReactElement => {
  const { hasOtherCharges } = props;
  const sx: Record<string, SxProps> = {
    noticesWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
  };

  return (
    <Box data-testid="reconnect-notice-content" sx={sx.noticesWrapper}>
      <DisclaimerNotice />
      {hasOtherCharges && <OtherChargesNotice />}
    </Box>
  );
};

export default ReconnectNoticeContent;
