import React from 'react';

interface IServiceSelectionProps {
  children: React.ReactNode;
}

const ServiceSelection: React.FC<IServiceSelectionProps> = (props: IServiceSelectionProps): React.ReactElement => {
  const { children } = props;
  return <>{children}</>;
};

export default ServiceSelection;
