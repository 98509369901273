import { AccountInformationDesktop, AccountInformationMobile } from 'reconnect-service/components';

import { useResponsiveRendering } from 'theme/breakpoints/responsiveHooks';

interface IReconnectAccountInformationProps {
  accountDetailsResponse: IAccountDetailsResponse;
  accountNumber: number;
}

const ReconnectAccountInformation: React.FC<IReconnectAccountInformationProps> = (
  props: IReconnectAccountInformationProps,
): React.ReactElement => {
  const { accountDetailsResponse, accountNumber } = props;
  const desktopAccountInfo = (
    <AccountInformationDesktop accountInfo={accountDetailsResponse} accountNumber={accountNumber} />
  );
  const mobileAccountInfo = (
    <AccountInformationMobile accountInfo={accountDetailsResponse} accountNumber={accountNumber} />
  );

  const AccountInformation = useResponsiveRendering(mobileAccountInfo, desktopAccountInfo, desktopAccountInfo);
  return AccountInformation;
};

export default ReconnectAccountInformation;
