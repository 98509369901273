import { reconnectService } from 'component-library/header/header';
import HeaderDesktop from 'component-library/header/HeaderDesktop';
import HeaderMobile from 'component-library/header/HeaderMobile';
import { reconnectHeaderCancelClickEvent } from 'gtm/gtmEventsReconnect';
import useConfig from 'hooks/useConfig';
import { googleTagManager } from 'services';

const headerCancelButtonHandler = (): void => {
  const {
    urls: { currentBillUrl = '' },
  } = useConfig();
  googleTagManager.trackButtonClick({
    ...reconnectHeaderCancelClickEvent,
    authenticationStatus: true,
  });
  window.location.href = currentBillUrl;
};

export const mobileHeader = (
  <HeaderMobile titleText={reconnectService} isHeaderButtonRendered onClick={headerCancelButtonHandler} />
);
export const desktopHeader = (
  <HeaderDesktop titleText={reconnectService} isHeaderButtonRendered onClick={headerCancelButtonHandler} />
);
