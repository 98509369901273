import React from 'react';
import { PaymentOptionsContainer, PaymentOptionsProvider } from 'reconnect-service/components';

import { RestoreDetails } from 'api/eligibility-api/EligibilityCheckResponse';
import PaymentProcessBanner from 'component-library/banners/PaymentProcessBanner';
import { DialogProvider } from 'component-library/dialog/DialogContext';

type ReconnectDefaultFormProps = IReconnectServiceFormProps;

const ReconnectDefaultContent: React.FC<ReconnectDefaultFormProps> = (
  props: ReconnectDefaultFormProps,
): React.ReactElement => {
  const { billComparisonResponse, experianResponse, restoreDetails } = props;

  return (
    <>
      <PaymentOptionsProvider>
        <DialogProvider>
          <PaymentOptionsContainer
            billComparisonResponse={billComparisonResponse}
            experianResponse={experianResponse}
            restoreDetails={restoreDetails as RestoreDetails}
          />
        </DialogProvider>
      </PaymentOptionsProvider>
      <PaymentProcessBanner />
    </>
  );
};

export default ReconnectDefaultContent;
