export const apiPaths: Record<ApiPath, string> = {
  accountDetailsApiPath: '/api/accounts',
  dunningLockDetails: '/dunning-lock-details?checkEligibility=true',
  eligibilityApiPath: '/eligibility-check',
  experianApiPath: '/experian',
  paymentAgreementDetailsApiPath: '/payment-agreement-details?checkEligibility=true',
  paymentAgreementPlanCreationApiPath: '/payment-agreement',
  paymentExtensionDetailsApiPath: '/payment-extension-details?checkEligibility=true',
  paymentExtensionLockApiPath: '/schedule-pe-lock',
  reconnectServiceApiPath: '/reconnect',
  refactoredEligibilityApiPath: '/V2/eligibility-check',
  restoreDetailsApiPath: '/restore-details?checkEligibility=true',
  signInApiPath: '/api/signIn',
};
