import { Box, SxProps, useTheme } from '@mui/material';

import { useUserState } from 'auth/authenticate';
import { DteButton } from 'component-library/buttons/DteButton';
import {
  getPaymentAgreemmentActionDetails,
  paymentAgreementClickEvents,
  PaymentAgreementEventLabels,
} from 'gtm/gtmEventsPaymentAgreement';
import { useConfig, usePaymentAgreementDetails } from 'hooks';
import { googleTagManager } from 'services';

import messages from '../messages';

const PAViewCurrentBillButton: React.FC = (): React.ReactElement => {
  const {
    urls: { currentBillUrl = '' },
  } = useConfig();
  const { isAuthenticated } = useUserState();
  const { paymentAgreementDetails } = usePaymentAgreementDetails({ shouldFetch: false });
  const theme = useTheme();

  const { cashOnly, downPaymentStatus } = paymentAgreementDetails;

  const redirectToCurrentBillHandler = (): void => {
    if (downPaymentStatus) {
      googleTagManager.trackEvent({
        ...paymentAgreementClickEvents,
        actionDetail: getPaymentAgreemmentActionDetails(downPaymentStatus),
        actionObject: PaymentAgreementEventLabels.viewCurrentBill,
        userType: cashOnly === 'Y' ? PaymentAgreementEventLabels.cashOnlyUser : PaymentAgreementEventLabels.defaultUser,
        authenticationStatus: isAuthenticated,
      });
    }

    window.location.href = currentBillUrl;
  };

  const sx: Record<string, SxProps> = {
    buttonWrapper: {
      display: 'flex',
      gap: '10px',
      justifyContent: 'center',
    },
    button: {
      [theme.breakpoints.down('tablet')]: {
        maxWidth: '300px',
      },
    },
  };

  return (
    <Box sx={sx.buttonWrapper} data-testid="view-current-bill-button">
      <DteButton
        onClick={redirectToCurrentBillHandler}
        sx={sx.button}
        text={messages.viewCurrentBill}
        variant="primary"
      />
    </Box>
  );
};

export default PAViewCurrentBillButton;
