import { useContext, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, FormControlLabel, Radio, SxProps, useTheme } from '@mui/material';

import RadioDefaultIcon from 'component-library/icons/RadioDefaultIcon';
import RadioErrorIcon from 'component-library/icons/RadioErrorIcon';
import RadioSelectedIcon from 'component-library/icons/RadioSelectedIcon';

import isInputTenTimesMinimumAmountDue from '../../paymentOptions/isInputTenTimesMinimumAmountDue';
import ListAmountDue from '../../paymentOptions/ListAmountDue';
import PayAnotherAmtDescription from '../../paymentOptions/PayAnotherAmtDescription';
import RadioLabelAdapter from '../../paymentOptions/RadioLabelAdapterV2';
import RadioLabelMinAmount from '../../paymentOptions/RadioLabelMinAmountV2';
import RadioLabelWithInput from '../../paymentOptions/RadioLabelWithInput';
import WarningTenTimesOver from '../../paymentOptions/WarningTenTimesOver';
import { PaymentOptionsContext } from '../../PaymentOptionsContext';
import { PaymentOption } from '../../PaymentOptionsModels';
import { PaymentOptionLabel } from '../radio-options/label';
import PastDueAndReconnectPaymentBreakdown from '../radio-options/payment-breakdown/PastDuePaymentBreakdown';

import messages from './messages';

interface IOptionSelectDesktopProps {
  paymentInfo: IMpscPaymentInfo;
}

const OptionSelectDesktop: React.FC<IOptionSelectDesktopProps> = (props: IOptionSelectDesktopProps): JSX.Element => {
  const { paymentInfo } = props;
  const { inputValue } = useContext(PaymentOptionsContext);
  const theme = useTheme();
  const sx: Record<string, SxProps> = {
    rootWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
    },
    optionSelectDesktopWrapper: {
      display: 'flex',
      '.MuiFormControlLabel-root': {
        width: '327px', // controls width of radio button and radio label root element
      },
      border: `1px solid ${theme.palette.dteGrey.dark}`,
    },
    failOptionSelectDesktopWrapper: {
      display: 'flex',
      '.MuiFormControlLabel-root': {
        width: '327px', // controls width of radio button and radio label root element
      },
      border: `1px solid ${theme.palette.dteRed.dark}`,
    },
    optionSelectWithWarningWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    payAnotherAmountWrapper: {
      display: 'flex',
    },
    formControlLabel: {
      margin: '0px',
      padding: '20px 30px 20px 0px',
    },
    formControlLabelWithList: {
      margin: '0px',
      borderRight: `1px solid ${theme.palette.dteGrey.main}`,
    },
    listAndDescriptionWrapper: {
      width: 'calc(100% - 327px)',
    },
    radioButton: {
      marginLeft: '10px',
      padding: '14px',
    },
  };

  const { depositAmount, minAmountToRestore, otherCharges, reconnectionFee, totalAmount, totalPastDueAmount } =
    paymentInfo;

  const hasOtherCharges = otherCharges > 0;

  const oldPaymentInfo: IPaymentInfo = {
    currentBillAmount: 0,
    pastDueAmount: totalPastDueAmount,
    reconnectAmount: reconnectionFee,
    securityDepositAmount: depositAmount,
    minimumAmountDue: minAmountToRestore,
    totalAmountDue: totalAmount,
  };

  const { formState } = useFormContext();
  const validationsSx =
    formState.errors.PaymentOptions?.type !== 'noOptionSelected'
      ? sx.optionSelectDesktopWrapper
      : sx.failOptionSelectDesktopWrapper;

  const optionSelectDesktopWithWarningWrapper = {
    ...validationsSx,
    ...sx.optionSelectWithWarningWrapper,
  };

  const minAmountDueLabel = (
    <RadioLabelAdapter
      label={<RadioLabelMinAmount />}
      hasOtherCharges={hasOtherCharges}
      calculatedTotal={minAmountToRestore}
    />
  );

  const pastDueAmountLabel = (
    <PaymentOptionLabel
      labelAmount={totalAmount}
      labelDisclaimer={messages.pastDueDisclaimerLabel}
      labelSubject={messages.pastDueSubjectLabel}
    />
  );

  const dteRadioIcon =
    formState.errors.PaymentOptions?.type !== 'noOptionSelected' ? <RadioDefaultIcon /> : <RadioErrorIcon />;

  const radio2Ref = useRef<HTMLInputElement>(null);

  const handleTabIndexOnChange = (): void => {
    if (radio2Ref && radio2Ref.current) {
      radio2Ref.current.tabIndex = 0;
    }
  };

  return (
    <Box sx={sx.rootWrapper} data-testid="option-selection-desktop-container">
      {hasOtherCharges ? (
        <Box sx={validationsSx} data-testid="option-total-amt-due-container">
          <FormControlLabel
            control={<Radio checkedIcon={<RadioSelectedIcon />} icon={dteRadioIcon} sx={sx.radioButton} />}
            data-testid="total-amount-due-radio"
            label={pastDueAmountLabel}
            sx={sx.formControlLabelWithList}
            value={PaymentOption.TOTAL_AMOUNT_DUE}
          />
          <Box sx={sx.listAndDescriptionWrapper}>
            <PastDueAndReconnectPaymentBreakdown {...paymentInfo} />
          </Box>
        </Box>
      ) : (
        <></>
      )}
      <Box sx={validationsSx} data-testid="option-min-amt-due-container">
        <FormControlLabel
          control={<Radio checkedIcon={<RadioSelectedIcon />} icon={dteRadioIcon} sx={sx.radioButton} />}
          data-testid="min-amount-due-radio"
          label={minAmountDueLabel}
          sx={sx.formControlLabelWithList}
          value={PaymentOption.MINIMUM_AMOUNT_DUE}
        />
        <Box sx={sx.listAndDescriptionWrapper}>
          <ListAmountDue paymentInfo={oldPaymentInfo} isMinimumAmountDue />
        </Box>
      </Box>
      <Box sx={optionSelectDesktopWithWarningWrapper} data-testid="option-pay-another-amt-container">
        <Box sx={sx.payAnotherAmountWrapper}>
          <FormControlLabel
            data-testid="pay-another-amount-radio"
            value={PaymentOption.PAY_ANOTHER_AMOUNT}
            control={
              <Radio
                checkedIcon={<RadioSelectedIcon />}
                icon={dteRadioIcon}
                inputRef={radio2Ref}
                onChange={handleTabIndexOnChange}
                sx={sx.radioButton}
                tabIndex={-1}
              />
            }
            label={<RadioLabelWithInput labelOnChange={handleTabIndexOnChange} />}
            sx={sx.formControlLabel}
          />
          <Box sx={sx.listAndDescriptionWrapper}>
            <PayAnotherAmtDescription minAmountDue={minAmountToRestore} />
          </Box>
        </Box>
        {isInputTenTimesMinimumAmountDue(inputValue, minAmountToRestore) ? <WarningTenTimesOver /> : <></>}
      </Box>
    </Box>
  );
};

export default OptionSelectDesktop;
