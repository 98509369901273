import { SxProps, Typography, useTheme } from '@mui/material';

import messages from './messages';

export const ReconnectNoticeTitle: React.FC = (): React.ReactElement => {
  const theme = useTheme();

  const sx: Record<string, SxProps> = {
    titleWrapper: {
      color: theme.palette.primary.main,
      fontSize: '18px',
      fontWeight: '600',
    },
  };

  return (
    <Typography data-testid="reconnect-notice-title" sx={sx.titleWrapper} variant="body2">
      {messages.title}
    </Typography>
  );
};
