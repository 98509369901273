import { ReactNode } from 'react';
import { Box, SxProps, Typography, useTheme } from '@mui/material';

import { ReadOnlyCurrencyMask } from 'component-library/currency/ReadOnlyCurrencyMask';

import messages from './messages';

type Props = {
  label: ReactNode;
  hasOtherCharges?: boolean;
  calculatedTotal: number;
};

const RadioLabelAdapter: React.FC<Props> = (props: Props): JSX.Element => {
  const { label, hasOtherCharges, calculatedTotal } = props;
  const theme = useTheme();
  const sx: Record<string, SxProps> = {
    helperText: {
      paddingTop: '8px',
      color: theme.palette.secondary.main,
    },
    labelDescription: {
      paddingTop: '8px',
    },
    rootWrapper: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '22px',
      color: theme.palette.primary.main,
      [theme.breakpoints.up('tablet')]: {
        paddingRight: '36px',
      },
    },
  };

  // TODO: remove after other charges is fully integrated

  const formattedTotal: JSX.Element = ReadOnlyCurrencyMask(calculatedTotal);

  return (
    <Box sx={sx.rootWrapper}>
      {label}
      <Typography data-testid="radio-label-amount-due" sx={sx.labelDescription} variant="title">
        {formattedTotal}
      </Typography>
      {hasOtherCharges && (
        <Typography fontSize={12} fontWeight={400} lineHeight="16.34px" sx={sx.helperText}>
          {messages.minAmountHelperText}
        </Typography>
      )}
    </Box>
  );
};

export default RadioLabelAdapter;
