import PageHeaders from 'constants/pageHeader';
import { base404ForPaAndPo, notFound404PageViewEvent, Page404ForPaAndPoEventLabels } from 'gtm/gtmEvents404';

const getGtmViewEvent = (headerTitle: string): GtmEventProps => {
  switch (headerTitle) {
    case PageHeaders.PAYMENT_AGREEMENT:
      return {
        ...base404ForPaAndPo,
        transactionStepName: Page404ForPaAndPoEventLabels.transactionStepNamePa,
      };

    case PageHeaders.PAYMENT_EXTENSION:
      return {
        actionDetail: 'pe page not found',
        actionObject: 'pe page not found',
        transactionStepName: 'collections error',
        transactionType: 'payment extension',
      };

    case PageHeaders.PAYMENT_OFFERS:
      return {
        ...base404ForPaAndPo,
        transactionStepName: Page404ForPaAndPoEventLabels.transactionStepNamePo,
      };

    case PageHeaders.SERVICE_PROTECTION:
      return { ...notFound404PageViewEvent, authenticationStatus: true };

    default:
      return {
        actionDetail: 'reconnect page not found',
        actionObject: 'reconnect page not found',
        transactionStepName: 'collections error',
        transactionType: 'reconnect',
      };
  }
};

export default getGtmViewEvent;
