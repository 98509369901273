export interface PaymentInfo {
  pastDueAmount: number;
  reconnectAmount: number;
  securityDepositAmount: number;
  currentBillAmount: number;
  minimumAmountDue: number;
  totalAmountDue: number;
}

export enum PaymentOption {
  MINIMUM_AMOUNT_DUE = 'minimum-amount-due',
  TOTAL_AMOUNT_DUE = 'total-amount-due',
  PAST_DUE_AMOUNT = 'past-due-amount',
  PAY_ANOTHER_AMOUNT = 'pay-another-amount',
}
