import React, { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Routes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';

import useConfig from 'hooks/useConfig';
import Qualtrics from 'services/qualtrics/Qualtrics';

import { useGoogleTagManager } from './gtm/useGoogleTagManager';
import { theme } from './theme/theme';
import { appRoutes } from './AppRoutes';

import './App.css';

function App(): JSX.Element {
  const {
    baseUrls: { REACT_APP_DYNATRACE_URL },
  } = useConfig();

  useEffect(() => {
    if (REACT_APP_DYNATRACE_URL) {
      const dynatraceScriptElement = document.createElement('script');
      dynatraceScriptElement.type = 'text/javascript';
      dynatraceScriptElement.title = 'dynatrace-script';
      dynatraceScriptElement.src = REACT_APP_DYNATRACE_URL;
      dynatraceScriptElement.crossOrigin = 'anonymous';
      document.head.appendChild(dynatraceScriptElement);
    }
  }, [REACT_APP_DYNATRACE_URL]);

  useGoogleTagManager();

  return (
    <Qualtrics>
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>{appRoutes}</Routes>
        </ThemeProvider>
      </HelmetProvider>
    </Qualtrics>
  );
}

export default App;
