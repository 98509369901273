import { useQuery } from '@tanstack/react-query';
import jwtDecode from 'jwt-decode';

import useConfig from 'hooks/useConfig';
import { logger } from 'services';

import { TokenResponse } from './TokenResponse';

interface UserState {
  isAuthenticated: boolean;
  webUserId?: string;
  getBpId: () => number;
}

export const appendGigyaScript = (): Promise<boolean> =>
  new Promise<boolean>((resolve: Function) => {
    const { gigyaSiteKey } = useConfig();
    let gigyaScript = document.getElementById('gigya-script') as HTMLScriptElement;
    // only append if it isn't already there
    if (gigyaScript) {
      resolve(true);
    } else {
      gigyaScript = document.createElement('script');
      gigyaScript.id = 'gigya-script';
      gigyaScript.crossOrigin = 'anonymous';
      gigyaScript.src = gigyaSiteKey;
      gigyaScript.onload = (): void => {
        resolve(true);
      };
      document.head.appendChild(gigyaScript);
    }
  });

export const retrieveJwt = (): Promise<string> =>
  new Promise<string>((resolve: Function, reject: Function) => {
    const {
      urls: { loginUrl = '' },
    } = useConfig();
    const jwtCallback = (response: TokenResponse): void => {
      const jwt = response.id_token;
      if (jwt) {
        localStorage.setItem('JWT', jwt);
        resolve(jwt);
      } else {
        // TODO - remove from localStorage?
        // localStorage.removeItem('JWT');
        logger.error('User is not authenticated. Redirecting to Login.');
        window.location.href = loginUrl;
        reject(new Error('User is not authenticated. Redirecting to Login.'));
      }
    };

    // @ts-ignore
    window.gigya.accounts.getJWT({
      callback: jwtCallback,
      expiration: 2700,
    });
  });

export const retrieveWebUserId = (): Promise<string> =>
  new Promise<string>((resolve: Function) => {
    const accountInfoCallback = (response: { profile: { email: string } }): void => {
      const webUserId = response.profile.email;
      localStorage.setItem('webUserId', webUserId);
      resolve(webUserId);
    };

    // @ts-ignore
    window.gigya.accounts.getAccountInfo({
      callback: accountInfoCallback,
    });
  });

export const useUserState = (): UserState => {
  const gigyaScript = document.getElementById('gigya-script') as HTMLScriptElement;
  const { data: isGigyaScriptLoaded } = useQuery({
    queryKey: ['append-gigya-script'],
    queryFn: appendGigyaScript,
    enabled: process.env.REACT_APP_LOCAL_MOCK !== 'true' && !gigyaScript,
    retry: 0,
    refetchOnWindowFocus: false,
    onError: (error: string) => {
      logger.error(error);
    },
  });

  const { data: jwt } = useQuery({
    queryKey: ['user-auth'],
    queryFn: retrieveJwt,
    enabled: !!isGigyaScriptLoaded,
    retry: 0,
    refetchOnWindowFocus: false,
    onError: (error: string) => {
      logger.error(error);
    },
  });

  const { data: webUserId } = useQuery({
    queryKey: ['web-user'],
    queryFn: retrieveWebUserId,
    enabled: !!jwt,
    retry: 0,
    refetchOnWindowFocus: false,
    onError: (error: string) => {
      logger.error(error);
    },
  });

  // exposing function instead of value, so that we only extract from the JWT when we need it
  const getBpId = (): number => {
    // jwt library to retrieve claim.sub
    const jwtJson = jwtDecode<{ sub: number }>(jwt!);
    return jwtJson.sub;
  };

  if (process.env.REACT_APP_LOCAL_MOCK !== 'true') {
    return {
      isAuthenticated: !!jwt,
      webUserId,
      getBpId,
    };
  }
  return {
    isAuthenticated: true,
    webUserId: 'mocked webUserId',
    getBpId,
  };
};
