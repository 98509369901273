import React, { useEffect, useState } from 'react';

import { useConfig } from 'hooks';

import appendQualtricsScript from './appendQualtricsScript';

const Qualtrics: React.FC<PropsWithJustChildren> = (props: PropsWithJustChildren): React.ReactElement => {
  const { children } = props;
  const [isAppended, setIsAppended] = useState(false);
  const { qualtrics } = useConfig();

  useEffect((): void => {
    if (qualtrics?.qsiId && !isAppended) {
      appendQualtricsScript(qualtrics);
      setIsAppended(true);
    }
  }, [qualtrics, isAppended]);

  return <>{children}</>;
};

export default Qualtrics;
