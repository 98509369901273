import { Box, SxProps } from '@mui/material';

import { useUserState } from 'auth/authenticate';
import { DteCtaLink } from 'component-library/links/DteCtaLink';
import { paymentAgreementClickEvents, PaymentAgreementEventLabels } from 'gtm/gtmEventsPaymentAgreement';
import { useConfig } from 'hooks';
import { googleTagManager } from 'services';

import messages from '../messages';

const sx: Record<string, SxProps> = {
  buttonWrapper: {
    display: 'flex',
    gap: '10px',
    justifyContent: 'center',
  },
  linkWrapper: {
    display: 'flex',
    gap: '8px',
    justifyContent: 'center',
  },
};

const ViewScheduledPaymentLink: React.FC = (): React.ReactElement => {
  const { isAuthenticated } = useUserState();
  const {
    urls: { accountHistoryUrlFromSchedueledPa = '' },
  } = useConfig();

  const handleClick = (): void => {
    googleTagManager.trackEvent({
      ...paymentAgreementClickEvents,
      actionDetail: PaymentAgreementEventLabels.scheduledDownPayment,
      actionObject: PaymentAgreementEventLabels.viewScheduledPayment,
      authenticationStatus: isAuthenticated,
    });
  };
  return (
    <Box onClick={handleClick} sx={sx.linkWrapper}>
      <DteCtaLink href={accountHistoryUrlFromSchedueledPa} name={messages.viewScheduledPayment} />
    </Box>
  );
};

export default ViewScheduledPaymentLink;
