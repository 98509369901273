import { Box, styled, Theme } from '@mui/material';

import { ReadOnlyCurrencyMask } from 'component-library';

import BreakdownListItem from './BreakdownListItem';
import messages from './messages';
import { StyledListItem } from './StyledListItems';

const PastDueAndReconnectPaymentBreakdown: React.FC<IMpscPaymentInfo> = (
  props: IMpscPaymentInfo,
): React.ReactElement => {
  const { otherCharges, reconnectionFee, depositAmount, totalAmount, totalPastDueAmount } = props;

  const BreakdownContainer = styled('div')({
    width: '100%',
  });

  const AmountDue = styled(StyledListItem)(({ theme }: { theme: Theme }) => ({
    ...theme.typography.body2,
    background: theme.palette.dteGrey.light,
    color: theme.palette.primary.main,
    fontWeight: 600,
  }));

  return (
    <BreakdownContainer data-testid="past-due-and-reconnect-payment-breakdown">
      <BreakdownListItem amount={totalPastDueAmount} itemDescription={messages.balancePriorToCurrentBillText} />
      <BreakdownListItem amount={otherCharges} itemDescription={messages.otherChargesText} />
      <BreakdownListItem amount={depositAmount} itemDescription={messages.securityDepositText} />
      <BreakdownListItem amount={reconnectionFee} itemDescription={messages.reconnectionFeeText} />
      <AmountDue>
        <Box>{messages.amountDueText}</Box>
        <Box>{ReadOnlyCurrencyMask(totalAmount)}</Box>
      </AmountDue>
    </BreakdownContainer>
  );
};

export default PastDueAndReconnectPaymentBreakdown;
