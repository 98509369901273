import { unitedWayPhoneNumber } from 'collections-copy';

import useConfig from 'hooks/useConfig';
import { googleTagManager } from 'services';

import {
  yLockEnergyAssistanceProgramsLinkOnClickEvent,
  yLockSubmitDocumentsButtonClickEvent,
  yLockUnitedWayLinkOnClickEvent,
  yLockViewCurrentBillLinkOnClickEvent,
  yLockViewEvent,
} from './gtmEventsYLock';

export const onPageViewHandler = (): void => {
  googleTagManager.trackViewEvent({
    ...yLockViewEvent,
    authenticationStatus: true,
  });
};

export const viewCurrentBillOnClickHandler = (): void => {
  const {
    urls: { currentBillUrl = '' },
  } = useConfig();
  googleTagManager.trackButtonClick({
    ...yLockViewCurrentBillLinkOnClickEvent,
    authenticationStatus: true,
  });
  window.location.href = currentBillUrl;
};

export const submitDocumentsOnClickHandler = (): void => {
  googleTagManager.trackButtonClick({
    ...yLockSubmitDocumentsButtonClickEvent,
    authenticationStatus: true,
  });
};

export const energyAssistanceProgramsLinkOnClickHandler = (): void => {
  googleTagManager.trackButtonClick({
    ...yLockEnergyAssistanceProgramsLinkOnClickEvent,
    authenticationStatus: true,
  });
};

export const unitedWayLinkOnClickHandler = (): void => {
  googleTagManager.trackButtonClick({
    ...yLockUnitedWayLinkOnClickEvent,
    actionObject: unitedWayPhoneNumber,
    authenticationStatus: true,
  });
};
