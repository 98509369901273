import { styled, Theme } from '@mui/material';

export const StyledListItem = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '36px',
  [theme.breakpoints.down('tablet')]: {
    padding: '8px 24px',
  },
  [theme.breakpoints.up('tablet')]: {
    padding: '8px 12px',
  },
}));
