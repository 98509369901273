import * as React from 'react';
import { SxProps, Typography, useTheme } from '@mui/material';

import { Bold } from 'component-library';

import messages from './messages';

const MpscInformation: React.FC = (): React.ReactElement => {
  const theme = useTheme();

  const sx: Record<string, SxProps> = {
    optionDescription: {
      color: theme.palette.primary.main,
      paddingBottom: '12px',
    },
    boldText: {
      fontWeight: '700',
    },
  };

  return (
    <Typography data-testid="mpsc-information" sx={sx.optionDescription} variant="body1">
      {messages.label}
      <Bold data-testid="mpsc-bold-information" sx={sx.boldText}>
        {messages.boldLabel}
      </Bold>
    </Typography>
  );
};

export default MpscInformation;
