import { styled, Theme, Typography } from '@mui/material';

import ReconnectNoticeComponent from './ReconnectNoticeContent';
import { ReconnectNoticeTitle } from './ReconnectNoticeTitle';

interface Props {
  hasOtherCharges?: Boolean;
}

const Container = styled(Typography)(({ theme }: { theme: Theme }) => ({
  backgroundColor: theme.palette.dteGrey.light,
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  padding: '20px 30px',
}));

const ReconnectNotice: React.FC<Props> = (props: Props): React.ReactElement => {
  const { hasOtherCharges } = props;
  return (
    <Container data-testid="reconnect-notice">
      {hasOtherCharges && <ReconnectNoticeTitle />}
      <ReconnectNoticeComponent hasOtherCharges={hasOtherCharges} />
    </Container>
  );
};

export default ReconnectNotice;
