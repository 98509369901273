import { Box, styled, Theme } from '@mui/material';

import useConfig from 'hooks/useConfig';

import DownPaymentSummary from './DownPaymentSummary';
import InstallmentSummary from './InstallmentSummaryV2';

interface IPaymentDetailsProps {
  downPaymentAmount: string;
  downPaymentStatus: string;
  installmentAmount: string;
  numberOfInstallmentsOpted: number;
}

interface IPaymentDetailsContainerProps {}

interface IPaymentDetailsContainerPropsWithTheme {
  numberOfInstallmentsOpted?: number;
  theme: Theme;
}

export const PaymentDetailsV2: React.FC<IPaymentDetailsProps> = (props: IPaymentDetailsProps) => {
  const { downPaymentStatus, downPaymentAmount, installmentAmount, numberOfInstallmentsOpted } = props;
  const {
    flags: { enablePAMonthlyRedesign },
  } = useConfig();

  const getPaymentDetailsContainerPadding = (noOfInstallmentsOpted: number): string => {
    if (enablePAMonthlyRedesign) {
      if (noOfInstallmentsOpted >= 25) {
        return '58.5px 24px';
      }
      return '79.5px 24px';
    }

    return '49px 24px';
  };

  const PaymentDetailsContainer = styled(Box)<IPaymentDetailsContainerProps>(
    ({ theme }: IPaymentDetailsContainerPropsWithTheme) => ({
      backgroundColor: theme.palette.dteGrey.light,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.down('tablet')]: {
        padding: '24px',
      },
      [theme.breakpoints.up('tablet')]: {
        padding: getPaymentDetailsContainerPadding(numberOfInstallmentsOpted),
        width: numberOfInstallmentsOpted >= 25 ? '100%' : '50%',
      },
    }),
  );

  return (
    <PaymentDetailsContainer data-testid="payment-details">
      <Box display="flex" flexDirection="column" gap="24px">
        <InstallmentSummary
          installmentAmount={installmentAmount}
          numberOfInstallmentsOpted={numberOfInstallmentsOpted}
        />
        {numberOfInstallmentsOpted < 25 ? (
          <DownPaymentSummary downPaymentAmount={downPaymentAmount} downPaymentStatus={downPaymentStatus} />
        ) : null}
      </Box>
    </PaymentDetailsContainer>
  );
};
