class ConfigStore {
  static instance: IApplicationConfig;

  private constructor(instance: IApplicationConfig) {
    ConfigStore.instance = instance;
  }

  public static get config(): IApplicationConfig {
    return ConfigStore.instance;
  }

  public static async load(): Promise<ConfigStore> {
    const response = await fetch('/config.json', { cache: 'no-cache' });
    const config = await response.json();

    return new ConfigStore(config);
  }
}

export default ConfigStore;
