import { Box, SxProps } from '@mui/material';

const sx: Record<string, SxProps> = {
  rootWrapper: {
    width: '44px',
    height: '44px',
  },
};

const OtherChargesNoticeIcon = (): React.ReactElement => (
  <Box data-testid="other-charges-notice-icon" sx={sx.rootWrapper}>
    <svg width="44" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6182 21.4111H29.7569M12.6182 24.6422H29.7569M12.6182 27.5923H29.7569M12.6182 30.9639H29.7569M12.6182 34.0545H29.7569"
        stroke="#1E3575"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.9697 27.02C31.847 24.9273 31.6314 21.572 31.4482 18.7786C31.3522 17.3141 32.5095 16.0733 33.972 16.0733C35.4298 16.0733 36.5854 17.3073 36.495 18.7673L35.9769 27.1359C35.9128 28.1708 35.0577 28.9773 34.0245 28.9773C32.9441 28.9773 32.0332 28.1023 31.9697 27.02ZM36.2939 33.4531L36.2772 33.6393C36.1686 34.8487 35.145 35.7669 33.9352 35.7401C32.746 35.7138 31.773 34.782 31.691 33.5912L31.6782 33.4048C31.5852 32.0541 32.6581 30.9101 34.0072 30.9213C35.3602 30.9326 36.4153 32.1008 36.2939 33.4531Z"
        stroke="#1E3575"
        strokeWidth="1.4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1468 6.80371H12.0804C10.0494 6.80371 8.39803 8.44088 8.38052 10.4718L8.15114 37.0803C8.13349 39.1281 9.7826 40.8007 11.8304 40.8121L31.5793 40.9218C33.3617 40.9317 34.8118 39.4896 34.8118 37.7072H33.4118C33.4118 38.7134 32.5932 39.5274 31.5871 39.5219L11.8382 39.4121C10.5652 39.4051 9.54012 38.3653 9.55109 37.0923L9.78047 10.4839C9.79136 9.22141 10.8179 8.20371 12.0804 8.20371H15.1468V6.80371ZM27.9307 8.20371H31.1118C32.3821 8.20371 33.4118 9.23346 33.4118 10.5037V14.5278C33.4118 14.9144 33.7252 15.2278 34.1118 15.2278C34.4984 15.2278 34.8118 14.9144 34.8118 14.5278V10.5037C34.8118 8.46026 33.1553 6.80371 31.1118 6.80371H27.9307V8.20371Z"
        fill="#1E3575"
      />
      <path
        d="M21.6089 17.4778C25.4882 17.4778 28.633 14.333 28.633 10.4538C28.633 6.57447 25.4882 3.42969 21.6089 3.42969C17.7296 3.42969 14.5848 6.57447 14.5848 10.4538C14.5848 14.333 17.7296 17.4778 21.6089 17.4778Z"
        stroke="#1E3575"
        strokeWidth="1.4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6947 7.47966V6.27734"
        stroke="#1E3575"
        strokeWidth="1.4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6947 14.6935V13.4912"
        stroke="#1E3575"
        strokeWidth="1.4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9231 11.9721C19.9231 11.9721 20.0497 13.4275 21.6317 13.4908C23.2137 13.5541 23.4035 12.3517 23.4035 12.0986C23.4035 11.8455 23.4035 10.8963 21.6949 10.4533C19.9231 10.0104 20.0497 8.93462 20.1129 8.80806C20.1762 8.6815 20.2395 7.60574 21.6317 7.47918C23.0238 7.35262 23.5301 8.6815 23.5301 8.93462"
        stroke="#1E3575"
        strokeWidth="1.4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Box>
);

export default OtherChargesNoticeIcon;
