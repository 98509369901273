import React from 'react';

import useConfig from 'hooks/useConfig';

import ReconnectDefaultFormContent from './ReconnectDefaultFormContent';
import ReconnectMPSCFormContent from './ReconnectMPSCFormContent';

type ReconnectServiceFormProps = IReconnectServiceFormProps;

const ReconnectServiceForm: React.FC<ReconnectServiceFormProps> = (
  props: ReconnectServiceFormProps,
): React.ReactElement => {
  const { billComparisonResponse, experianResponse, restoreDetails } = props;
  const {
    flags: { enableMPSC },
  } = useConfig();

  return enableMPSC ? (
    <ReconnectMPSCFormContent experianResponse={experianResponse} restoreDetails={restoreDetails} />
  ) : (
    <ReconnectDefaultFormContent
      billComparisonResponse={billComparisonResponse?.data!}
      experianResponse={experianResponse}
      restoreDetails={restoreDetails}
    />
  );
};

export default ReconnectServiceForm;
