import useConfig from 'hooks/useConfig';
import { googleTagManager } from 'services';

import gtmPeScheduledBaseEvent from './gtmPeScheduledBaseEvent';

const onCurrentBillButtonClick = (): void => {
  const {
    urls: { currentBillUrl = '' },
  } = useConfig();

  googleTagManager.trackButtonClick({
    ...gtmPeScheduledBaseEvent,
    actionDetail: 'scheduled',
    actionObject: 'view current bill',
    authenticationStatus: true,
  });
  window.location.href = currentBillUrl;
};

export default onCurrentBillButtonClick;
