import { useContext, useState } from 'react';

import { useUserState } from 'auth/authenticate';
import { DteButton } from 'component-library/buttons/DteButton';
import { DialogContext } from 'component-library/dialog/DialogContext';
import { DteModal } from 'component-library/modal/DteModal';
import { googleTagManager } from 'services';

import { EnrollmentContext } from '../EnrollmentContext';

import messages from './messages';
import ReviewPaymentModalContent from './ReviewPaymentModalContent';

interface IReviewPaymentButton {
  value: string;
  buttonLabel: string;
}

const ReviewPaymentModal: React.FC<IReviewPaymentButton> = (props: IReviewPaymentButton): React.ReactElement => {
  const { buttonLabel, value } = props;
  const { setOpen } = useContext(DialogContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isAuthenticated } = useUserState();
  const { enrollmentInformation } = useContext(EnrollmentContext);

  const onClickHandler = (): void => {
    const gtmOnViewCurrentBillClick = {
      actionDetail: `${enrollmentInformation.installmentDuration} months`,
      actionObject: value === 'SchedulePayment' ? 'schedule down payment' : 'pay later',
      transactionStepName: 'pa detail page',
      transactionType: 'payment agreement',
      userType: 'web',
    };

    googleTagManager.trackButtonClick({ ...gtmOnViewCurrentBillClick, authenticationStatus: isAuthenticated });
    setOpen(true);

    // **** NOTE: Had to move gtm modal load view event to the onClick due to the modal provider causing issues with useffects running multiple times, regardless of dep. array
    const gtmPAEnrollmentConfirmationModal = {
      actionDetail: 'modal',
      actionObject: 'pa enrollment',
      transactionStepName: 'enrollment confirmation',
      transactionType: 'payment agreement',
      userType: value === 'CashOnly' ? 'cash only' : 'web',
    };

    googleTagManager.trackViewEvent({ ...gtmPAEnrollmentConfirmationModal, authenticationStatus: isAuthenticated });
  };

  const onCloseHandler = (): void => {
    if (!isLoading) {
      const gtmOnGoBackClick = {
        actionDetail: 'return to pa details',
        actionObject: 'go back',
        transactionStepName: 'enrollment confirmation',
        transactionType: 'payment agreement',
        userType: value === 'CashOnly' ? 'cash only' : 'web',
      };

      googleTagManager.trackButtonClick({ ...gtmOnGoBackClick, authenticationStatus: isAuthenticated });
      setOpen(false);
    }
  };

  return (
    <>
      <DteButton onClick={onClickHandler} tabIndex={0} text={buttonLabel} variant="primary" />
      <DteModal
        content={
          <ReviewPaymentModalContent downPaymentMethod={value} isLoading={isLoading} setIsLoading={setIsLoading} />
        }
        data-testid="review-and-confirm-payment-modal"
        isLoading={isLoading}
        onCloseHandler={onCloseHandler}
        title={messages.modalTitle}
      />
    </>
  );
};

export default ReviewPaymentModal;
