import React from 'react';
import { PaymentOptionsProvider } from 'reconnect-service/components';
import { PaymentOptionsForm } from 'reconnect-service/components/form';
import MpscInformation from 'reconnect-service/components/paymentOptions/MpscInformation';
import ReconnectNotice from 'reconnect-service/components/ReconnectNotice';

import { DialogProvider } from 'component-library';

type ReconnectMPSCFormProps = IReconnectMPSCFormProps;

const ReconnectMPSCForm: React.FC<ReconnectMPSCFormProps> = (props: ReconnectMPSCFormProps): React.ReactElement => {
  const { experianResponse, restoreDetails } = props;
  const { otherCharges = '0.00' } = restoreDetails;

  const hasOtherCharges = Number(otherCharges) > 0;

  return (
    <>
      <MpscInformation />
      <PaymentOptionsProvider>
        <DialogProvider>
          <PaymentOptionsForm experianResponse={experianResponse} restoreDetails={restoreDetails} />
        </DialogProvider>
      </PaymentOptionsProvider>
      <ReconnectNotice hasOtherCharges={hasOtherCharges} />
    </>
  );
};

export default ReconnectMPSCForm;
