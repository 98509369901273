import { styled } from '@mui/material';

import useConfig from 'hooks/useConfig';

import { DtePrimaryButton } from '../../buttons/DtePrimaryButton';

import messages from './messages';

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  height: '40px',
  width: '300px',
}));

const ContactUsButton: React.FC<ErrorPageContactUsButtonClickHandler> = (
  props: ErrorPageContactUsButtonClickHandler,
): React.ReactElement => {
  const { onClick } = props;
  const {
    urls: { contactUsLink = '' },
  } = useConfig();

  const handleClick = (): void => {
    if (onClick) {
      onClick();
    }
    window.location.href = contactUsLink;
  };

  return (
    <Container>
      <DtePrimaryButton dataTestId="generic-error-contact-us" name={messages.contactUs} onClick={handleClick} />
    </Container>
  );
};

export default ContactUsButton;
