import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';

import { useUserState } from 'auth/authenticate';
import { DtePrimaryButton } from 'component-library/buttons/DtePrimaryButton';
import { routes } from 'constants/';
import { useCreatePaymentAgreementPlan } from 'hooks';
import { googleTagManager, logger } from 'services';

import { EnrollmentContext } from '../../EnrollmentContext';
import messages from '../messages';

interface PayLaterConfirmButtonProps {
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

const PayLaterConfirmButton: React.FC<PayLaterConfirmButtonProps> = ({
  setIsLoading,
}: PayLaterConfirmButtonProps): React.ReactElement => {
  const navigate = useNavigate();
  const { isAuthenticated } = useUserState();

  const handleGtmOnConfirmClick = (): void => {
    const gtmOnPayLaterConfirmClick = {
      actionDetail: 'move to pa enrolled page',
      actionObject: 'confirm enrollment',
      transactionStepName: 'enrollment confirmation',
      transactionType: 'payment agreement',
      userType: 'web',
    };

    googleTagManager.trackButtonClick({ ...gtmOnPayLaterConfirmClick, authenticationStatus: isAuthenticated });
  };

  const onSuccess = (httpResponse: AxiosResponse<ICreatePaymentAgreementPlanResponse>): void => {
    const { documentNumber, messageReply } = httpResponse.data;

    const successCodeReceived = messageReply.replyCode.toLowerCase() === 'success';
    const documentNumberMorethanZero = documentNumber > 0;

    if (successCodeReceived && documentNumberMorethanZero) {
      logger.info('[Logging...] Plan Create API Call Success!', httpResponse);
      navigate(routes.paymentAgreement.enrolled);
    } else {
      navigate(routes.error.paymentAgreement);
    }
  };

  const { enrollmentInformation } = useContext(EnrollmentContext);
  const { createPaymentAgreementPlan } = useCreatePaymentAgreementPlan({
    onSuccess,
  });

  const {
    accountNumber: contractAccount,
    downPaymentAmount: downpaymentAmount,
    installmentAmount,
    installmentDuration,
  } = enrollmentInformation;

  return (
    <DtePrimaryButton
      dataTestId="review-payment-modal-pay-later-confirm-enrollment"
      name={messages.actionButtonConfirmLabel}
      onClick={(): void => {
        handleGtmOnConfirmClick();

        if (setIsLoading) {
          setIsLoading(true);
        }

        createPaymentAgreementPlan({
          contractAccount,
          downpaymentAmount,
          installmentAmount,
          numberOfInstallments: parseInt(installmentDuration, 10),
        });
      }}
    />
  );
};

export default PayLaterConfirmButton;
