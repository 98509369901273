import { styled, Theme } from '@mui/material';

import useConfig from 'hooks/useConfig';

import { Breadcrumb } from './Breadcrumb';
import messages from './messages';

interface IViewCurrentBillLinkProps {
  onClickHandler?: () => void;
}

const LinkContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  fontWeight: '600',
  width: '100%',
  [theme.breakpoints.down('tablet')]: {
    justifyContent: 'center',
  },
}));

const ViewCurrentBillLink = (props: IViewCurrentBillLinkProps): React.ReactElement => {
  const { onClickHandler } = props;
  const { urls } = useConfig();

  return (
    <LinkContainer>
      <Breadcrumb
        data-testid="view-current-bill-link"
        href={urls.currentBillUrl}
        onClickHandler={onClickHandler}
        text={messages.viewCurrentBillLabel}
      />
    </LinkContainer>
  );
};

export default ViewCurrentBillLink;
