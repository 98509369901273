import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, SxProps, Typography, useTheme } from '@mui/material';

import { useUserState } from 'auth/authenticate';
import { DtePrimaryButton } from 'component-library/buttons/DtePrimaryButton';
import { DteSecondaryButton } from 'component-library/buttons/DteSecondaryButton';
import HeaderDesktop from 'component-library/header/HeaderDesktop';
import HeaderMobile from 'component-library/header/HeaderMobile';
import { DteTextLink } from 'component-library/links/DteTextLink';
import { PageTemplate } from 'component-library/page-templates/PageTemplate';
import { PageTitles } from 'constants/';
import {
  ReconnectErrorContactUsClickEvent,
  ReconnectErrorPhoneClickEvent,
  ReconnectErrorViewCurrentBillClickEvent,
  ReconnectErrorViewEvent,
} from 'gtm/gtmEventsReconnect';
import { useGoogleTagManager } from 'gtm/useGoogleTagManager';
import useConfig from 'hooks/useConfig';
import { googleTagManager } from 'services';
import { useResponsiveRendering } from 'theme/breakpoints/responsiveHooks';

import messages from './messages';

const customerContactNumber = '(800) 477-4747';

const ReconnectErrorPage: React.FC = (): React.ReactElement => {
  const {
    urls: { currentBillUrl = '', contactUsLink = '' },
  } = useConfig();
  const { isAuthenticated } = useUserState();
  const isGoogleTagManagerLoaded = useGoogleTagManager();
  const headerTitle = 'Reconnect Service';
  const mobileHeader = <HeaderMobile titleText={headerTitle} isHeaderButtonRendered={false} />;
  const desktopHeader = <HeaderDesktop titleText={headerTitle} isHeaderButtonRendered={false} />;
  const pageHeader = useResponsiveRendering(mobileHeader, desktopHeader, desktopHeader);

  useEffect(() => {
    if (isGoogleTagManagerLoaded && isAuthenticated) {
      googleTagManager.trackViewEvent({
        ...ReconnectErrorViewEvent,
        authenticationStatus: isAuthenticated,
      });
    }
  }, [isGoogleTagManagerLoaded, isAuthenticated]);

  const PaymentExtensionErrorContent: React.FC = (): React.ReactElement => {
    const theme = useTheme();
    const sx: Record<string, SxProps> = {
      contentWrapper: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        margin: '40px 0px',
      },
      title: {
        color: theme.palette.primary.main,
      },
      subtitle: {
        color: theme.palette.secondary.main,
      },
      buttonWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        width: '100%',
      },
      wordWrap: {
        overflowWrap: 'break-word',
        whiteSpace: 'nowrap',
      },
    };

    const contactUsHandler = (): void => {
      googleTagManager.trackButtonClick({
        ...ReconnectErrorContactUsClickEvent,
        authenticationStatus: isAuthenticated,
      });
      window.location.href = contactUsLink;
    };

    const phoneNumberHandler = (): void => {
      googleTagManager.trackButtonClick({
        ...ReconnectErrorPhoneClickEvent,
        authenticationStatus: isAuthenticated,
      });
    };

    const redirectToCurrentBillHandler = (): void => {
      googleTagManager.trackButtonClick({
        ...ReconnectErrorViewCurrentBillClickEvent,
        authenticationStatus: isAuthenticated,
      });
      window.location.href = currentBillUrl;
    };

    return (
      <Box sx={sx.contentWrapper}>
        <Typography variant="title" sx={sx.title} role="heading" aria-level={1}>
          {messages.title}
        </Typography>
        <Box sx={sx.subtitle}>
          <Typography variant="body1">
            {messages.pleaseContactUsText}
            <Box component="span" sx={sx.wordWrap}>
              <DteTextLink
                href={`tel:${customerContactNumber}`}
                name={customerContactNumber}
                variant="body2"
                onClick={phoneNumberHandler}
              />
            </Box>
            {messages.assistanceText}
          </Typography>
        </Box>
        <Box sx={sx.buttonWrapper}>
          <DtePrimaryButton
            href={`tel:${customerContactNumber}`}
            name={messages.contactUsButtonLabel}
            onClick={contactUsHandler}
          />
          <DteSecondaryButton name={messages.viewCurrentBillButtonLabel} onClick={redirectToCurrentBillHandler} />
        </Box>
      </Box>
    );
  };

  const content = <PaymentExtensionErrorContent />;

  return (
    <>
      <Helmet>
        <title>{PageTitles.RECONNECT_ERROR}</title>
      </Helmet>
      <PageTemplate
        header={pageHeader}
        allViewportPaddingTop="0px"
        tabletDesktopContentWidth="470px"
        dataTestId="reconnect-error-page-container"
      >
        {content}
      </PageTemplate>
    </>
  );
};

export default ReconnectErrorPage;
