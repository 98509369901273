import { AxiosResponse } from 'axios';

import { axiosGet } from 'api/adapter/axios-adapter';

export const adminRefactoredEligibilityCheck = async (
  jwt: string,
  url: string,
): Promise<AxiosResponse<IRefactoredEligibilityResponse>> =>
  axiosGet<IRefactoredEligibilityResponse>(url, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });

export const adminDunningLockDetailsApi = async (
  jwt: string,
  url: string,
): Promise<AxiosResponse<IDunningLockDetailsResponse>> =>
  axiosGet<IDunningLockDetailsResponse>(url, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
