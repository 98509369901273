import { styled, Theme } from '@mui/material';

import useConfig from 'hooks/useConfig';
import googleTagManager from 'services/googleTagManager';

import ViewCurrentBillLink from '../../component-library/links/ViewCurrentBillLink';

import OffersSection from './offers-section';
import PaymentOffersDescription from './PaymentOffersDescription';
import PaymentOffersHeading from './PaymentOffersHeading';

const Container = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
  [theme.breakpoints.down('tablet')]: {
    maxWidth: '100%',
  },
}));

const TopContentContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
}));

const PaymentOffersContent: React.FC = (): React.ReactElement => {
  const {
    urls: { currentBillUrl = '' },
  } = useConfig();

  const handleViewCurrentBillClick = (): void => {
    googleTagManager.trackButtonClick({
      actionDetail: 'select payment plan',
      actionObject: 'view current bill',
      authenticationStatus: true,
      transactionStepName: 'pa offers',
      transactionType: 'payment agreement',
      userType: 'web',
    });
    window.location.href = currentBillUrl;
  };

  return (
    <Container>
      <TopContentContainer>
        <ViewCurrentBillLink onClickHandler={handleViewCurrentBillClick} />
        <PaymentOffersHeading />
        <PaymentOffersDescription />
      </TopContentContainer>
      <OffersSection />
    </Container>
  );
};

export default PaymentOffersContent;
