import React, { SyntheticEvent, useContext, useEffect } from 'react';
import { Controller, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { Button, FormControl, RadioGroup, SxProps, Typography, useTheme } from '@mui/material';

import { RestoreDetails } from 'api/eligibility-api/EligibilityCheckResponse';
import ExperianResponse from 'api/experian-api/ExperianResponse';
import { useUserState } from 'auth/authenticate';
import { reconnectBaseProperties } from 'gtm/gtmEventsReconnect';
import useConfig from 'hooks/useConfig';
import { googleTagManager } from 'services';
import { useResponsiveRendering } from 'theme/breakpoints/responsiveHooks';
import { reconnectConfirmationPath } from 'urls-and-paths';

import ErrorMessage from '../ErrorMessage';
import { SecurityDepositNote } from '../paymentOptions';
import { PaymentOptionsContext } from '../PaymentOptionsContext';
import { PaymentOption } from '../PaymentOptionsModels';

import messages from './messages';
import { MpscSelectPaymentLabel, OptionSelectDesktop, OptionSelectMobile } from './option-select';

const defaultValues = {
  PaymentOptions: '',
};

interface IPaymentOptionsFormProps {
  experianResponse: ExperianResponse;
  restoreDetails: RestoreDetails | IRestoreDetails;
  hasOtherCharges?: Boolean;
}
const PaymentOptionsForm: React.FC<IPaymentOptionsFormProps> = (props: IPaymentOptionsFormProps): JSX.Element => {
  const {
    urls: { lambdaReconnectUrl = '', paymentCenterReconnectUrl = '' },
  } = useConfig();
  const { isAuthenticated } = useUserState();
  const { experianResponse, restoreDetails } = props;
  const theme = useTheme();
  const formMethods = useForm({ defaultValues, reValidateMode: 'onSubmit' });
  const { control, clearErrors, formState, handleSubmit } = formMethods;
  const { inputValue } = useContext(PaymentOptionsContext);

  const sx: Record<string, SxProps> = {
    submitButton: {
      background: theme.palette.dteBlue.main,
      borderRadius: 'unset',
      boxShadow: 'unset',
      color: theme.palette.common.white,
      height: '40px',
      width: '100%',
      margin: 'auto',
      marginBottom: '32px',
      maxWidth: '300px',
      '&:hover': {
        background: theme.palette.dteBlue.dark,
        boxShadow: 'unset',
      },
      '&:focus': {
        background: theme.palette.dteBlue.dark,
        boxShadow: 'unset',
        color: theme.palette.common.white,
      },
      textTransform: 'none',
    },
  };

  const {
    cardAllowedIndicator,
    cashAllowedIndicator,
    checkAllowedIndicator,
    contractAccountNo = 0,
    installations = [],
    minAmountToRestore = '0.00',
    reconnectionFee,
    totalPastDueAmount,
    totalAmount,
    depositAmount,
    otherCharges = '0.00',
    premise = 0,
  } = restoreDetails;

  const mpscPaymentInfo = {
    depositAmount: parseFloat(depositAmount),
    minAmountToRestore: parseFloat(minAmountToRestore),
    otherCharges: parseFloat(otherCharges),
    reconnectionFee: parseFloat(reconnectionFee),
    totalAmount: parseFloat(totalAmount),
    totalPastDueAmount: parseFloat(totalPastDueAmount),
  };

  const optionSelectTabletAndDesktop = <OptionSelectDesktop paymentInfo={mpscPaymentInfo} />;
  const optionSelectMobile = <OptionSelectMobile paymentInfo={mpscPaymentInfo} />;
  const responsiveOptionSelect = useResponsiveRendering(
    optionSelectMobile,
    optionSelectTabletAndDesktop,
    optionSelectTabletAndDesktop,
  );

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onSubmit = async (data: FieldValues) => {
    const formData = new URLSearchParams();

    const webUserId = localStorage.getItem('webUserId');
    const selectedPaymentOption: PaymentOption = data.PaymentOptions;
    if (selectedPaymentOption === PaymentOption.MINIMUM_AMOUNT_DUE) {
      formData.append('paymentAmount', minAmountToRestore || '0.00');
      formData.append('paymentOption', PaymentOption.MINIMUM_AMOUNT_DUE);

      localStorage.setItem(`paymentAmount_${webUserId}`, minAmountToRestore || '0.00');
    } else if (selectedPaymentOption === PaymentOption.TOTAL_AMOUNT_DUE) {
      formData.append('paymentAmount', totalAmount);
      formData.append('paymentOption', PaymentOption.TOTAL_AMOUNT_DUE);

      localStorage.setItem(`paymentAmount_${webUserId}`, totalAmount);
    } else {
      if (inputValue) {
        formData.append('paymentAmount', inputValue.replaceAll(',', ''));
        formData.append('paymentOption', PaymentOption.PAY_ANOTHER_AMOUNT);
      }

      localStorage.setItem(`paymentAmount_${webUserId}`, inputValue);
    }
    formData.append('minimumAmountDue', minAmountToRestore || '');
    formData.append('businessId', experianResponse.businessID.toString());
    formData.append('premisesId', premise.toString());
    formData.append('cashAllowedIndicator', `${cashAllowedIndicator}`);
    formData.append('cardAllowedIndicator', `${cardAllowedIndicator}`);
    formData.append('checkAllowedIndicator', `${checkAllowedIndicator}`);
    formData.append('installationNumber', `${installations[0]}`);
    formData.append('accounts', contractAccountNo.toString());
    formData.append('reconnectDetailsUrl', window.location.href);
    formData.append('redirectUrl', window.location.hostname + reconnectConfirmationPath);

    const actionObjectMap: { [key in PaymentOption]: string } = {
      [PaymentOption.MINIMUM_AMOUNT_DUE]: 'payment min due',
      [PaymentOption.PAST_DUE_AMOUNT]: 'past due amount',
      [PaymentOption.PAY_ANOTHER_AMOUNT]: 'payment other amount',
      [PaymentOption.TOTAL_AMOUNT_DUE]: 'payment total due',
    };

    const actionDetailMap: { [key in PaymentOption]: string } = {
      [PaymentOption.MINIMUM_AMOUNT_DUE]: minAmountToRestore || '0.00',
      [PaymentOption.PAST_DUE_AMOUNT]: totalPastDueAmount,
      [PaymentOption.TOTAL_AMOUNT_DUE]: totalAmount,
      [PaymentOption.PAY_ANOTHER_AMOUNT]: inputValue,
    };

    const actionObject = actionObjectMap[selectedPaymentOption];
    const actionDetail = actionDetailMap[selectedPaymentOption];

    googleTagManager.trackButtonClick({
      ...reconnectBaseProperties,
      actionDetail,
      actionObject,
      authenticationStatus: isAuthenticated,
      transactionStepName: 'reconnect details',
      transactionStepNumber: '1',
    });

    await fetch(lambdaReconnectUrl, {
      method: 'POST',
      body: formData.toString(),
      redirect: 'follow',
      credentials: 'include',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    })
      .then(() => {
        localStorage.setItem('webUserId', webUserId || '');
        localStorage.setItem(`depositAmount_${webUserId}`, depositAmount);
        localStorage.setItem(`reconnectionFee_${webUserId}`, reconnectionFee);
        localStorage.setItem(`totalPastDueAmount_${webUserId}`, totalPastDueAmount);
        window.location.href = paymentCenterReconnectUrl;
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (parseFloat(minAmountToRestore) > 0) {
      googleTagManager.trackViewEvent({
        ...reconnectBaseProperties,
        actionDetail: minAmountToRestore,
        actionObject: 'min due',
        authenticationStatus: isAuthenticated,
        transactionStepName: 'reconnect details',
        transactionStepNumber: '1',
      });
    }
  }, [minAmountToRestore]);

  const handleKeyDownOnForm = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      const { activeElement } = document;
      if ((activeElement as HTMLInputElement)?.type !== 'button') {
        event.preventDefault();
      }
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          // eslint-disable-next-line @typescript-eslint/typedef, @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-shadow
          render={({ field: { onChange, value, ref, ...props } }) => (
            <FormControl variant="standard">
              <MpscSelectPaymentLabel />
              <RadioGroup
                {...props}
                ref={ref}
                onChange={(event: SyntheticEvent): void => {
                  clearErrors('PaymentOptions');
                  onChange(event);
                }}
                onKeyDown={handleKeyDownOnForm}
                value={value}
              >
                <>{responsiveOptionSelect}</>
              </RadioGroup>
              {formState.errors.PaymentOptions?.type === 'noOptionSelected' ? (
                <ErrorMessage errorText="Please make a selection to continue." />
              ) : (
                <></>
              )}
              {formState.errors.PaymentOptions?.type === 'inputEmptyOrZero' ? (
                <ErrorMessage errorText="Please make corrections to continue." />
              ) : (
                <></>
              )}
              <SecurityDepositNote />
              <Button data-testid="make-a-payment-button" type="submit" variant="contained" sx={sx.submitButton}>
                <Typography fontWeight={600} variant="body2">
                  {messages.makeAPaymentButtonLabel}
                </Typography>
              </Button>
            </FormControl>
          )}
          name="PaymentOptions"
          control={control}
          rules={{
            validate: {
              noOptionSelected: (value: string) => !(value === '') || 'no option is selected',
              inputEmptyOrZero: (value: string) =>
                !(value === 'pay-another-amount' && (!inputValue || Number(inputValue) === 0)) || 'input is empty',
            },
          }}
        />
      </form>
    </FormProvider>
  );
};

export default PaymentOptionsForm;
