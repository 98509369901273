import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useConfig from 'hooks/useConfig';
import logger from 'services/logger';

import { lockStatusErrorPath } from '../../urls-and-paths';

import { useWLockViewModel } from './useWLockViewModel';
import { WLockView } from './WLockView';

export const WLock = (): React.ReactElement => {
  const {
    urls: { currentBillUrl = '' },
  } = useConfig();
  const navigate = useNavigate();
  const { isEligibilitySuccess, isEligibilityError, eligibilityResponse, eligibilityError } = useWLockViewModel();
  const notEligible = isEligibilitySuccess && eligibilityResponse?.data.eligible.dunningLock !== 'Y';

  useEffect(() => {
    if (notEligible) {
      window.location.href = currentBillUrl;
    }
    // React expecting eligibilityResponse to be passed in
    // eslint-disable-next-line
  }, [notEligible]);

  useEffect(() => {
    if (isEligibilityError) {
      logger.info('lock-status-eligibility Error', eligibilityError);
      navigate(lockStatusErrorPath);
    }
    // React expecting eligibilityError to be passed in
    // eslint-disable-next-line
  }, [isEligibilityError]);

  return <WLockView />;
};
