import { Box, styled, SxProps, Theme, Typography, useTheme } from '@mui/material';

import OtherChargesNoticeIcon from 'component-library/icons/OtherChargesNoticeIcon';

import messages from './messages';

const NoticeTextContainer = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.primary.main,
  variant: 'body1',
}));

const OtherChargesNotice: React.FC = (): React.ReactElement => {
  const theme = useTheme();

  const sx: Record<string, SxProps> = {
    noticeWrapperFirstText: {
      alignItems: 'flex-start',
      color: theme.palette.secondary.main,
      display: 'flex',
      gap: '20px',
    },
  };

  return (
    <Box data-testid="other-charge-notice" sx={sx.noticeWrapperFirstText}>
      <OtherChargesNoticeIcon />
      <NoticeTextContainer data-testid="other-charge-text">{messages.secondStepMessage}</NoticeTextContainer>
    </Box>
  );
};

export default OtherChargesNotice;
